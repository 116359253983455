<template>
  <div v-if="content" class="container mx-auto">
    <OfferBlockingSlider v-if="content" :offers="sortedContent || []" />
  </div>
</template>
<script setup lang="ts">
import type { OfferType } from '~/composables/offers';
const props = defineProps({
  content: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
});

// sort the content of the offers according to the sort property
function sortItem(a: OfferType[0], b: OfferType[0]) {
  return (a?.sort || Number.MAX_VALUE) - (b?.sort || Number.MAX_VALUE);
}

const sortedContent = ref(props.content);
sortedContent.value.sort(sortItem);
</script>
