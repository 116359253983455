import type { HomeQuery } from '@robustastudio/e-commerce/common';
import { fetchQuery } from '@robustastudio/e-commerce/utils';

import { HomeDocument } from '~/graphql/home';
import type { Unpacked } from '~/types/utils';

type HomeItem = Unpacked<Unpacked<HomeQuery['homeItems']>['items']>;

export enum HomeComponentType {
  'PRODUCTS_CAROUSEL' = 'PRODUCTS_CAROUSEL',
  'OFFERS' = 'OFFERS',
  'FEATURED_CATEGORIES' = 'FEATURED_CATEGORIES',
  'FEATURED_BRANDS' = 'FEATURED_BRANDS',
  'ABOUT_BLOCKS' = 'ABOUT_BLOCKS',
  'BEST_SELLERS_CAROUSEL' = 'BEST_SELLERS',
  'RECENTLY_VIEWED_CAROUSEL' = 'RECENTLY_VIEWED',
}

export const sectionTypeMap = {
  carousel: HomeComponentType.PRODUCTS_CAROUSEL,
  banner: HomeComponentType.OFFERS,
  'featured-brands': HomeComponentType.FEATURED_BRANDS,
  'about-blocks': HomeComponentType.ABOUT_BLOCKS,
  default: HomeComponentType.FEATURED_CATEGORIES,
  recentlyViewedProducts: HomeComponentType.RECENTLY_VIEWED_CAROUSEL,
  bestSellers: HomeComponentType.BEST_SELLERS_CAROUSEL,
};

export enum SliderRenderStyles {
  'MAIN' = 'MAIN',
  'DEALS_BANNER' = 'DEALS_BANNER',
  'THREE_CARDS_LEFT' = 'THREE_CARDS_LEFT',
  'THREE_CARDS_RIGHT' = 'THREE_CARDS_RIGHT',
  'OFFERS_SLIDER' = 'OFFERS_SLIDER',
  'BLOCKS' = 'BLOCKS',
}

export enum ProductRenderStyle {
  'DEFAULT' = 'DEFAULT',
  'INVERTED' = 'INVERTED',
}

export const styleFn = (item: HomeItem) => {
  if (item?.type === 'banner')
    switch (item?.style) {
      case 'main':
        return SliderRenderStyles.MAIN;
      case 'three-cards-left':
        return SliderRenderStyles.THREE_CARDS_LEFT;
      case 'three-cards-right':
        return SliderRenderStyles.THREE_CARDS_RIGHT;
      case 'blocks':
        return SliderRenderStyles.BLOCKS;
      case 'deals-banner':
        return SliderRenderStyles.DEALS_BANNER;
      case 'offers-slider':
        return SliderRenderStyles.OFFERS_SLIDER;
      default:
        return SliderRenderStyles.MAIN;
    }

  if (item?.type === 'carousel')
    switch (item?.style) {
      case 'inverted':
        return ProductRenderStyle.INVERTED;
      default:
        return ProductRenderStyle.DEFAULT;
    }

  return ProductRenderStyle.DEFAULT;
};

export const fetchHomeQuery = fetchQuery(HomeDocument);
