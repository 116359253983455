<template>
  <div class="container mx-auto my-8 md:my-15">
    <div
      v-if="offer"
      class="relative OfferModelArt1 flex flex-wrap md:flex-nowrap flex-row md:gap-x-7 lg:gap-x-15 mx-4 md:mx-0"
    >
      <div class="OfferImage mb-11 w-[calc(50%-8px)] md:w-full flex-grow">
        <picture v-if="offer.image">
          <AppSource :srcset="offer?.image" media="(min-width: 640px)" width="460" height="597" format="avif" />
          <AppSource :srcset="offer?.image" media="(min-width: 640px)" width="460" height="597" format="webp" />
          <AppSource :srcset="offer?.image" media="(min-width: 640px)" width="460" height="597" format="jpeg" />
          <AppSource :srcset="offer?.mobileImage" media="(max-width: 640px)" width="312" height="405" format="avif" />
          <AppSource :srcset="offer?.mobileImage" media="(max-width: 640px)" width="312" height="405" format="webp" />
          <AppSource :srcset="offer?.mobileImage" media="(max-width: 640px)" width="312" height="405" format="jpeg" />
          <AppImage
            :src="offer?.image"
            :alt="String(offer?.title)"
            class="w-full h-full object-cover rounded-4xs aspect-[0.77]"
            loading="lazy"
          />
        </picture>
      </div>

      <div class="order-3 md:order-2 w-full mt-6 sm:mt-10 flex flex-col items-start justify-center gap-5 md:gap-7">
        <h2 v-if="offer?.title" class="font-bold text-lg md:text-xl">
          {{ offer?.title }}
        </h2>

        <p v-if="offer?.description" class="text-sm text-primary-1-60">
          {{ offer?.description }}
        </p>

        <AppButton v-if="offer?.cta" as="a" :href="offer?.href" class="whitespace-nowrap px-7">
          {{ offer?.cta }}
        </AppButton>
      </div>
      <div v-if="offer?.secondaryImage" class="order-2 md:order-3 w-[calc(50%-8px)] md:w-full ml-4 md:ml-0">
        <div class="OfferSecondaryImage__first">
          <NuxtImg
            class="w-full h-full lg:ml-9 object-cover rounded-4xl aspect-[1.8]"
            format="webp"
            :src="offer?.secondaryImage"
            :alt="String(offer?.title)"
            width="490"
            height="272"
            quality="75"
            loading="lazy"
          />
        </div>
        <div class="OfferSecondaryImage__second mr-25 mt-7 aspect-[0.77]">
          <NuxtImg
            class="w-full object-cover rounded-4xs aspect-[0.77]"
            format="webp"
            :src="offer?.thirdImage"
            :alt="String(offer?.title)"
            width="360"
            height="467"
            quality="75"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OfferType } from '~/composables/offers';

const props = defineProps({
  content: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
});

const offer = computed(() => props.content?.at(0));
</script>

<style lang="postcss" scoped>
.OfferImage {
  aspect-ratio: 0.77;
}

.OfferSecondaryImage {
  &__first {
    width: 159px;

    @screen md {
      width: 77%;
    }
  }
}

.OfferSecondaryImage {
  &__second {
    width: 107px;

    @screen md {
      width: 77%;
    }
  }
}
</style>
