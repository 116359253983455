<template>
  <div class="relative container mx-auto mb-7 md:mb-15">
    <DelayHydration>
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :loop="false"
        :slides-offset-after="0"
        :slides-offset-before="0"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        class="h-full relative"
      >
        <SwiperSlide
          v-for="(item, idx) in offers"
          :key="idx"
          :class="{
            'active-slide': currentIndex + 1 === idx,
          }"
        >
          <div class="OfferImage">
            <picture v-if="item">
              <AppSource
                :srcset="item.image"
                format="avif"
                media="(min-width: 768px)"
                width="1536"
                height="548"
                :quality="75"
              />
              <AppSource
                :srcset="item.image"
                format="webp"
                media="(min-width: 768px)"
                width="1536"
                height="548"
                :quality="75"
              />
              <AppSource
                :srcset="item.image"
                format="jpeg"
                media="(min-width: 768px)"
                width="1536"
                height="548"
                :quality="75"
              />

              <AppSource
                v-if="item.mobileImage"
                :srcset="item.mobileImage"
                media="(max-width: 768px)"
                width="768"
                height="600"
                format="avif"
                :quality="75"
              />
              <AppSource
                v-if="item.mobileImage"
                :srcset="item.mobileImage"
                media="(max-width: 768px)"
                width="768"
                height="600"
                format="webp"
                :quality="75"
              />
              <AppSource
                v-if="item.mobileImage"
                :srcset="item.mobileImage"
                media="(max-width: 768px)"
                width="768"
                height="600"
                format="jpeg"
                :quality="75"
              />
              <AppImage
                :src="item.image"
                :alt="item.title || ''"
                class="w-full h-full object-cover object-top rounded-4xl aspect-[1.28] md:aspect-[2.8]"
                :loading="idx === 0 ? 'eager' : 'lazy'"
                :fetchpriority="idx === 0 ? 'high' : 'auto'"
                :quality="75"
              />
            </picture>

            <svg class="svg">
              <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                <path
                  d="M0,0.087 C0,0.039,0.014,0,0.031,0 H0.969 C0.986,0,1,0.039,1,0.087 V0.782 C1,0.83,0.986,0.869,0.969,0.869 H0.523 C0.51,0.869,0.5,0.898,0.5,0.934 C0.5,0.971,0.49,1,0.477,1 H0.031 C0.014,1,0,0.961,0,0.913 V0.087"
                ></path>
              </clipPath>
            </svg>
            <div class="image__overlay"></div>
          </div>
          <section class="md:max-w-114 text-white absolute top-[60%] md:top-1/2 -translate-y-1/2 left-6 md:left-15">
            <header class="flex flex-col gap-3 md:gap-7 mb-3 md:mb-7">
              <h1 v-if="item.title" class="OfferTitle text-sm font-bold uppercase">
                {{ item.title }}
              </h1>
              <p v-if="item.description" class="font-bold text-2xl leading-none lg:leading-none">
                {{ item.description }}
              </p>
            </header>
            <AppButton
              v-if="item.cta"
              as="a"
              :href="item.href"
              class="px-7 py-3 text-primary-1-100 font-semibold whitespace-nowrap capitalize text-base leading-[3.25rem]"
              variant="white"
            >
              {{ item.cta }}
            </AppButton>
          </section>
        </SwiperSlide>

        <SwiperControls :current-index="currentIndex" @change="(e: number) => (currentIndex = e)" />
      </swiper>

      <aside
        class="w-[calc(100%-14px)] md:w-2/5 mt-7 md:mt-0 flex justify-end items-center md:absolute gap-7 -bottom-3 lg:bottom-0 right-15 z-10"
      >
        <section id="hero-slide-progress" class="mx-3 md:mr-0 w-full flex items-center gap-5 progress">
          <span class="text-primary-2-100">{{ String(currentIndex + 1).padStart(2, '0') }}</span>
          <div class="w-full relative border-t border-system-red-light">
            <progress
              class="h-[1px] absolute -top-[1px] border-t border-primary-2-100"
              :style="{ width: `${currentProgress}%` }"
              :value="Number(currentProgress)"
            ></progress>
          </div>
          <span class="text-primary-2-100">{{ String(offers.length).padStart(2, '0') }}</span>
        </section>
        <button
          class="w-10 h-10 outline-none [ flex items-center justify-center ]"
          :aria-label="$t('previous')"
          :class="{ 'transform scale-x-reverse': $i18n.locale === 'ar' }"
          @click="prevSlide"
        >
          <svg-icon-chevron-left class="text-primary-1-100" width="40" height="40" />
        </button>

        <button
          class="w-10 h-10 outline-none [ flex items-center justify-center ]"
          :aria-label="$t('next')"
          :class="{ 'transform scale-x-reverse': $i18n.locale === 'ar' }"
          @click="nextSlide"
        >
          <svg-icon-chevron-right class="text-primary-1-100" width="40" height="40" />
        </button>
      </aside>
    </DelayHydration>
  </div>
</template>
<script setup lang="ts">
import 'swiper/css';

import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
const modules = [Navigation, Autoplay];
const { t: $t } = useI18n({
  useScope: 'local',
});

const props = defineProps({
  offers: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
  type: {
    type: String as PropType<'default' | 'dimmed'>,
    default: 'default',
    validator: (value: string) => ['default', 'dimmed'].includes(value),
  },
});

const currentIndex = ref(0);
const numberOfSlides = ref(props.offers.length);

const currentProgress = computed(() => ((currentIndex.value + 1) / numberOfSlides.value) * 100);
function nextSlide() {
  currentIndex.value = getValidValue(currentIndex.value + 1);
}

function prevSlide() {
  currentIndex.value = getValidValue(currentIndex.value - 1);
}

function getValidValue(num: number) {
  return clamp(0, props.offers.length - 1, num);
}
</script>
<style lang="postcss" scoped>
:deep(.swiper-slide .swiper-slide-active) {
  width: 100%;
}
.swiper {
  margin: 0 14px;
}
.OfferImage {
  clip-path: none;
  border-radius: 28px;
  width: 100%;
  aspect-ratio: 1.28;

  @screen md {
    border-radius: 28px;
    clip-path: url(#my-clip-path);
    -webkit-clip-path: url(#my-clip-path);
    aspect-ratio: 2.8;
  }
  .image__overlay {
    border-radius: 28px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .svg {
    position: absolute;
    width: 0;
    height: 0;
  }
}
</style>
<i18n>
  {
    "en": {
      "fashionDeals": "fashion deals",
      "next": "Next Slide",
      "previous": "Previous Slide",
      "viewOffer": "View offer"
    },
    "ar": {
      "fashionDeals": "عروض الموضة",
      "next": "التالي",
      "previous": "السابق",
      "viewOffer": "عرض العرض"
    }
  }
</i18n>
