<template>
  <section class="container mx-auto mt-8 lg:mt-20 px-6 lg:px-15 py-8 lg:py-20 bg-primary-1-05 rounded-4xs">
    <h2 class="font-semibold text-primary-1-100 md:text-center text-lg md:text-xl">
      {{ $t('title') }}
    </h2>

    <DelayHydration>
      <div class="mt-7 md:mt-10 grid grid-cols-1 md:grid-cols-3 gap-x-7 gap-y-12 md:gap-15">
        <div v-for="(block, index) in blockContent" :key="index" class="flex flex-col gap-y-4">
          <NuxtImg
            :src="mediaUrl + block.image"
            :alt="block.title"
            loading="lazy"
            quality="75"
            class="w-6 h-6 object-contain"
          />
          <h3 class="text-primary-1-100 font-bold">{{ block.title }}</h3>
          <p class="text-primary-1-60">{{ block.description }}</p>
        </div>
      </div>
    </DelayHydration>
  </section>
</template>

<script setup lang="ts">
const {
  public: { mediaUrl },
} = useRuntimeConfig();
const { data: blockContent } = await useLazyAsyncData(async () => {
  const blockContent = await useHomeBlockSection();
  return blockContent;
});
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style scoped>
img {
  max-width: 44px;
}
</style>

<i18n>
{
    "en": {
      "title": "A Unique Experience",
      "learnMore": "Learn More"
    },
    "ar": {
      "title": "تجربة فريدة",
      "learnMore": "اعرف أكثر"
    }
}
</i18n>
