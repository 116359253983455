<template>
  <DelayHydration>
    <div v-if="content && content.items && content.items.length" class="mt-16">
      <CarouselWrapper
        :id="id"
        :title="content.title"
        :subtitle="content.subtitle"
        :type="fragmentType"
        :products="content.items"
      />
    </div>
  </DelayHydration>
</template>
<script setup lang="ts">
import type { CarouselContent } from '@robustastudio/e-commerce/common';

const props = defineProps({
  content: {
    type: Object as PropType<CarouselContent>,
    default: () => null,
  },
  componentStyle: {
    type: String,
    default: ProductRenderStyle.DEFAULT,
  },
  type: {
    type: String,
    default: 'default',
  },
  component: {
    type: Object,
    default: () => null,
  },
});
const fragmentType = computed(() => {
  return props.componentStyle === ProductRenderStyle.INVERTED ? 'inverted' : 'default';
});

const id = computed(() => {
  return props.content?.id?.toString() || '-1';
});
</script>
