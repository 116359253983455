<template>
  <span class="font-display"> {{ countdownToShow }} </span>
</template>

<script lang="ts" setup>
import { intervalToDuration, isBefore } from 'date-fns';

/**
 * A component for handling any countdown timer
 */
const props = defineProps({
  endDate: {
    type: String,
    required: true,
  },
});

let interval: ReturnType<typeof setInterval>;
const { t } = useI18n();

const timeLeft = ref<ReturnType<typeof intervalToDuration> | null>(null);
const hours = computed(() => {
  if (!timeLeft.value?.hours) {
    return '00';
  }

  return timeLeft.value?.hours < 10 ? `0${timeLeft.value?.hours}` : timeLeft.value?.hours;
});
const minutes = computed(() => {
  if (!timeLeft.value?.minutes) {
    return '00';
  }

  return timeLeft.value?.minutes < 10 ? `0${timeLeft.value?.minutes}` : timeLeft.value?.minutes;
});
const seconds = computed(() => {
  if (!timeLeft.value?.seconds) {
    return '00';
  }

  return timeLeft.value?.seconds < 10 ? `0${timeLeft.value?.seconds}` : timeLeft.value?.seconds;
});

// Concatenate days, hours, minutes, seconds and skip day if its 0
const countdownToShow = computed(() => {
  let countdown = '';

  if (timeLeft.value?.days) {
    countdown = `${timeLeft.value.days}${t('d')} `;
  }
  countdown += `${hours.value}:${minutes.value}:${seconds.value}`;

  return countdown;
});

onMounted(() => {
  calculateTimer();
});

function calculateTimer() {
  // Make sure it has not expired
  if (isBefore(new Date(), new Date(props.endDate))) {
    interval = setInterval(() => {
      timeLeft.value = intervalToDuration({
        start: new Date(),
        end: new Date(props.endDate),
      });
      timerCheck();
    }, 1000);
  }
}

function timerCheck() {
  if (!isBefore(new Date(), new Date(props.endDate))) {
    if (interval) {
      clearInterval(interval);
    }
    timeLeft.value = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
}

onUnmounted(() => {
  if (interval) {
    clearInterval(interval);
  }
});
</script>

<i18n>
{
  "en": {
    "d": "d"
  },
  "ar": {
    "d": " أيام"
  }
}
</i18n>
