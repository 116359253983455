<template>
  <div>
    <component :is="item.component" :content="content" v-bind="item.attributes" :type="componentStyle" />
  </div>
</template>
<script setup lang="ts">
import type { HomeComponentContent } from '@robustastudio/e-commerce/common';

const componentMapper: Record<string, ReturnType<typeof resolveComponent>> = {
  [SliderRenderStyles.MAIN]: resolveComponent('HomeOffersBlockingSliderFragment'), // Main Offers Slider
  [SliderRenderStyles.DEALS_BANNER]: resolveComponent('HomeOffersModelArt1'), // Best Deals Banner - 2nd section on homepage
  [SliderRenderStyles.OFFERS_SLIDER]: resolveComponent('LazyHomeOffersFragment'),
  [SliderRenderStyles.BLOCKS]: resolveComponent('HomeOffersBlockingSliderFragment'),
};

const props = defineProps({
  content: {
    type: Array as PropType<HomeComponentContent>,
    default: () => [],
  },
  componentStyle: {
    type: String,
    default: 'HomeOffersFragment',
  },
});

const item = computed(() => ({
  component: componentMapper[props.componentStyle],
  attributes: {},
}));
</script>
